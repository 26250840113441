const config: any = {};

config.env = 'dev';
config.milestonesAPIBaseUrl =
  process.env.HONA_API_BASE_URL ?? 'http://localhost:8080';
config.ignoreAuthenticationInDev = true;
config.cognito = {
  REGION: process.env.AWS_COGNITO_REGION ?? 'us-east-1',
  USER_POOL_ID: process.env.AWS_USER_POOLS_ID ?? 'us-east-1_45bniNeSW',
  APP_CLIENT_ID:
    process.env.AWS_USER_POOLS_WEB_CLIENT_ID ?? '6il0sokmq5bqceaivsu9cdeobv',
  clientAccountPoolId: process.env.AWS_USER_POOLS_ID_ML
    ? JSON.parse(process.env.AWS_USER_POOLS_ID_ML)?.AWS_USER_POOLS_ID_ML
    : 'us-east-1_C4M5GrAXZ',
  appwebclientId: process.env.AWS_USER_POOLS_WEB_CLIENT_ID_ML
    ? JSON.parse(process.env.AWS_USER_POOLS_WEB_CLIENT_ID_ML)
      ?.AWS_USER_POOLS_WEB_CLIENT_ID_ML
    : '5v542pi0mriln1jk89e09cj8e6',
  cookieStorage: {
    domain: process.env.COOKIE_DOMAIN ?? '.localhost',
    path: '/',
    expires: 365,
    sameSite: 'lax',
    secure: false,
  },
};

config.posthog = {
  apiKey: "phc_tDrVUmZWMlD2IyrB1v55UHUlRBBwT5ogYHy3l9R80Dj",
  apiHost: 'https://d144cslsyita8a.cloudfront.net',
};

export default config;
