import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import config from '../config/config';

let client: AxiosInstance;
const baseUrl = config.milestonesAPIBaseUrl;

export const getAuthHeader = (session: CognitoUserSession): string =>
  `Bearer ${session.getAccessToken().getJwtToken()}`;

// Handle token refreshing
const createAPIClient = async () => {
  const isPreviewMode =
    typeof window !== 'undefined' &&
    new URLSearchParams(window.location.search).get('preview') === 'true';
  if (config.env === 'dev' && config.ignoreAuthenticationInDev) {
    client = axios.create({
      params: isPreviewMode ? { preview: 'true' } : {},
    });
    return;
  }
  if (isPreviewMode) {
    client = axios.create({
      params: { preview: 'true' },
    });
    return;
  }
  const session = await Auth.currentSession();
  client = axios.create({
    headers: {
      common: {
        Authorization: getAuthHeader(session),
      },
    },
    params: isPreviewMode ? { preview: 'true' } : {},
  } as any);
  createAuthRefreshInterceptor(client, async (request) => {
    // Recreate client and update for future requests
    await createAPIClient();
    const newSession = await Auth.currentSession();
    // Update the Auth header for current request
    request.response.config.headers.Authorization = getAuthHeader(newSession);
  });
};

export const get = async (
  path: string,
  params?: any,
  axiosParams?: AxiosRequestConfig,
) => {
  if (!client) {
    await createAPIClient();
  }

  const { data } = await client.get(baseUrl + path, { params, ...axiosParams });
  return data;
};

export const put = async (path: string, payload: any) => {
  if (!client) {
    await createAPIClient();
  }
  const { data } = await client.put(baseUrl + path, payload);
  return data;
};

export const post = async (path: string, payload: any) => {
  if (!client) {
    await createAPIClient();
  }
  const { data } = await client.post(baseUrl + path, payload);
  return data;
};

export const del = async (path: string) => {
  if (!client) {
    await createAPIClient();
  }
  const { data } = await client.delete(baseUrl + path);
  return data;
};

export const multiPartPost = async (path: string, payload: any) => {
  if (!client) {
    await createAPIClient();
  }
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });
  const reqConfig: any = {
    method: 'post',
    baseURL: baseUrl,
    url: path,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };
  const { data } = await client(reqConfig);
  return data;
};
