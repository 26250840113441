import { useMemo, useState, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import AuthContextML from './AuthContextML';
import {
  useGetLoginIdentityFromAuthToken,
  useGetSessionFromCognito,
  useMutateCognitoSignIn,
} from '../../hooks/signin.hooks';
import trpc from '../../util/trpc';
import { setCookie } from '../../util/cookie';
import config from '../../config/config';

function AuthProviderML(props: { children: JSX.Element }) {
  const { children } = props;
  const isReviewApp = config.env === 'review';
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [loadingCustomAuthChallenge, setLoadingCustomAuthChallenge] = useState<
    boolean | null
  >(false);
  const [URLSearchParams] = useSearchParams();
  const authToken = URLSearchParams.get('c');
  const sessionSource = URLSearchParams.get('s');
  const { data: tokenLoginIdentity, isLoading: isLoadingTokenLoginIdentity } =
    useGetLoginIdentityFromAuthToken(authToken);
  const { data: session, isLoading: isLoadingSession } =
    useGetSessionFromCognito();
  const { mutate: recordLogin } = trpc.clientUser.recordLogin.useMutation();
  const { mutate: recordLogout } = trpc.clientUser.recordLogout.useMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const getSessionSource = (source: string | null) => {
    if (!source) return 'Unknown';
    switch (source) {
      case 'n':
        return 'NotificationLink';
      case 'r':
        return 'RefreshLink';
      case 'd':
        return 'DirectNav';
      default:
        return 'Unknown';
    }
  };
  const isLoading = isLoadingSession || isLoadingTokenLoginIdentity;
  const { mutate: signInWithToken } = useMutateCognitoSignIn({
    onSuccess: () => {
      setIsLoggedIn(true);
      setCookie('session-source', getSessionSource(sessionSource));
      recordLogin();
    },
    onError: async (e) => {
      setIsLoggedIn(false);
      setLoadingCustomAuthChallenge(false);
      console.error(e);
      await Auth.signOut();
      setErrorMessage(
        'Invalid or expired Link. Please send a new link to sign in.',
      );
    },
  });

  const decodeAuthToken = (signedToken: string) => {
    try {
      const token = signedToken.split('.')[0];
      const bytes = CryptoJS.enc.Base64.parse(token);
      const decodedToken = CryptoJS.enc.Utf8.stringify(bytes).replaceAll(
        '"',
        '',
      );
      return decodedToken as string;
    } catch (e) {
      console.log(e);
      setErrorMessage('Invalid Link. Please send a new link to sign in.');
    }
    return null;
  };
  const signIn = async () => {
    if (authToken && !loadingCustomAuthChallenge) {
      await Auth.signOut();
      const decodedToken = decodeAuthToken(authToken);
      setLoadingCustomAuthChallenge(true);
      signInWithToken({ decodedToken, authToken });
    }
  };
  const authenticate = async () => {
    try {
      if (!isReviewApp) {
        window.localStorage.clear();
        window.sessionStorage.clear();
      }
      const decodedSessionToken = session?.getIdToken()?.decodePayload();
      const loginIdentityFromCognitoUser = decodedSessionToken?.email
        ? decodedSessionToken?.email
        : decodedSessionToken?.phone_number;
      const doIdentitiesMatch =
        session &&
        tokenLoginIdentity &&
        loginIdentityFromCognitoUser &&
        loginIdentityFromCognitoUser === tokenLoginIdentity.loginIdentity;
      const isMagicLinkCognitoSession =
        decodedSessionToken && decodedSessionToken['custom:clicks'] >= 0;
      const identitiesDontMatch =
        tokenLoginIdentity &&
        loginIdentityFromCognitoUser &&
        loginIdentityFromCognitoUser !== tokenLoginIdentity.loginIdentity;

      if (!isLoading) {
        if (
          isMagicLinkCognitoSession &&
          !tokenLoginIdentity // session but no ML token
        ) {
          setIsLoggedIn(true);
          recordLogin();
          setLoadingCustomAuthChallenge(false);
        } else if (doIdentitiesMatch && isMagicLinkCognitoSession) {
          // session and ML token matches session token
          recordLogin();
          setIsLoggedIn(true);
          setLoadingCustomAuthChallenge(false);
        } else if (
          (authToken && !session) || // no session but Auth token
          identitiesDontMatch // identities don't match
        ) {
          signIn();
        } else if (!loadingCustomAuthChallenge) {
          await Auth.signOut();
          setLoadingCustomAuthChallenge(false);
          setIsLoggedIn(false);
        }
      }
    } catch (e) {
      console.log(e);
      setIsLoggedIn(false);
    }
  };
  useEffect(() => {
    authenticate();
  }, [session, tokenLoginIdentity]);

  const signOut = useCallback(async () => {
    await Auth.signOut();
    window.location.reload();
    recordLogout();
    setIsLoggedIn(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      loadingCustomAuthChallenge,
      signOut,
      errorMessage,
    }),
    [isLoggedIn],
  );

  return (
    <AuthContextML.Provider value={contextValue}>
      {children}
    </AuthContextML.Provider>
  );
}

export default AuthProviderML;
