import { createContext } from 'react';

const AuthContext = createContext<{
  isLoggedIn: boolean | null;
  isAuthenticated: () => Promise<boolean>;
  signIn: (username: string) => Promise<any>;
  answerCustomChallenge: (username: string, code: string) => Promise<boolean>;
  signOut: () => Promise<void>;
}>({
  isLoggedIn: null,
  isAuthenticated: () => Promise.resolve(false),
  signIn: () => Promise.resolve(null),
  answerCustomChallenge: () => Promise.resolve(true),
  signOut: () => Promise.resolve(),
});

export default AuthContext;
