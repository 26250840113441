const config: any = {};

config.env = 'review';

config.milestonesAPIBaseUrl =
  process.env.HONA_API_BASE_URL ?? 'https://staging-api.getmilestones.com';

config.cognito = {
  REGION: process.env.AWS_COGNITO_REGION ?? 'us-east-1',
  USER_POOL_ID: process.env.AWS_USER_POOLS_ID ?? 'us-east-1_45bniNeSW',
  APP_CLIENT_ID:
    process.env.AWS_USER_POOLS_WEB_CLIENT_ID ?? '6il0sokmq5bqceaivsu9cdeobv',
  clientAccountPoolId: process.env.AWS_USER_POOLS_ID_ML
    ? JSON.parse(process.env.AWS_USER_POOLS_ID_ML)?.AWS_USER_POOLS_ID_ML
    : 'us-east-1_C4M5GrAXZ',
  appwebclientId: process.env.AWS_USER_POOLS_WEB_CLIENT_ID_ML
    ? JSON.parse(process.env.AWS_USER_POOLS_WEB_CLIENT_ID_ML)
      ?.AWS_USER_POOLS_WEB_CLIENT_ID_ML
    : '5v542pi0mriln1jk89e09cj8e6',
};

config.posthog = {
  apiKey: "phc_5Ri9r6DSPpy35FrsIHYaJUXUOVUoXeJSAQnT01yHrGv",
  apiHost: 'https://d144cslsyita8a.cloudfront.net',
};

// HON-2961 add handling of company subdomain
config.clientApp = {
  baseUrl: 'https://staging.hona.app/',
  nextUrl: 'https://next.staging.hona.app/',
};

export default config;
