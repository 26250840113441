import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { sendMagicLink, getLoginIdentityFromAuthToken } from '../api/signIn';
import { MutationArguments } from './hooks';

export const useSendMagicLink = ({ onSuccess, onError }: MutationArguments) => {
  const queryClient = useQueryClient();
  return useMutation(
    (args: {
      loginIdentity: string;
      loginIdentityType: 'email' | 'phone';
      companySubdomain: string;
      path: string;
    }) =>
      sendMagicLink(
        args.loginIdentity,
        args.loginIdentityType,
        args.companySubdomain,
        args.path,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['magic-link-sent'],
        });
        if (onSuccess) onSuccess();
      },
      onError: (err) => {
        if (onError) onError(err);
      },
    },
  );
};

export const useGetLoginIdentityFromAuthToken = (authToken: string | null) => {
  if (!authToken) {
    return useQuery(['default-login-identity', authToken], () => null);
  }

  return useQuery(['default-login-identity', authToken], () =>
    getLoginIdentityFromAuthToken(authToken),
  );
};

export const useGetSessionFromCognito = () =>
  useQuery(['cognito-session'], async () => {
    try {
      return await Auth.currentSession();
    } catch (err) {
      return null;
    }
  });

export const useGetSessionUserFromCognito = () =>
  useQuery(['cognito-session', 'cognito-user-info'], () => {
    try {
      return Auth.currentUserInfo();
    } catch (err) {
      return null;
    }
  });

export const useMutateCognitoSignIn = ({
  onSuccess,
  onError,
}: MutationArguments) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (args: { decodedToken: string | null; authToken: string | null }) => {
      if (!args.authToken || !args.decodedToken)
        throw new Error('Missing arguments');
      const cognitoUser = await Auth.signIn(args.decodedToken);
      if (!cognitoUser) throw new Error('Could not sign in. No user found');
      const authResponse = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        args.authToken,
      );
      if (!authResponse || !authResponse.signInUserSession) {
        throw new Error('Could not sign in. Auth Challenge failed');
      }
      return authResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['cognito-session', 'cognito-user-info'],
        });
        if (onSuccess) onSuccess();
      },
      onError: (err) => {
        if (onError) onError(err);
      },
    },
  );
};
