import Cookie from 'universal-cookie';
import config from '../config/config';

export const getCookie = (cookieName: string) => {
  if (config.env === 'review') {
    // use local storage for review environment
    return localStorage.getItem(cookieName);
  }
  const cookie = new Cookie(null, {
    path: '/',
    domain: config.cognito.cookieStorage.domain,
  });
  return cookie.get(cookieName);
};

export const hasCookieValue = (cookieName: string, cookieValue: string) => {
  if (config.env === 'review') {
    // use local storage for review environment
    return localStorage.getItem(cookieName) === cookieValue;
  }
  const cookie = new Cookie(null, {
    path: '/',
    domain: config.cognito.cookieStorage.domain,
  });
  return cookie.get(cookieName) === cookieValue;
};

export const setCookie = (cookieName: string, cookieValue: string) => {
  if (config.env === 'review') {
    // use local storage for review environment
    localStorage.setItem(cookieName, cookieValue);
    return;
  }
  const cookie = new Cookie(null, {
    path: '/',
    domain: config.cognito.cookieStorage.domain,
  });
  cookie.set(cookieName, cookieValue);
};
