import { createContext } from 'react';

const AuthContextML = createContext<{
  isLoggedIn: boolean | null;
  loadingCustomAuthChallenge: boolean | null;
  errorMessage: string | null;
  signOut: () => Promise<void>;
}>({
  isLoggedIn: null,
  errorMessage: null,
  loadingCustomAuthChallenge: null,
  signOut: () => Promise.resolve(),
});

export default AuthContextML;
