import type { AppRouter } from 'milestones-api/trpc';
import { createTRPCReact } from '@trpc/react-query';

/**
 * Create a trpc client using the react-query hooks. Hooks work exactly the same as react-query.
 * @link https://trpc.io/docs/client/react
 */
const trpc = createTRPCReact<AppRouter>();

export default trpc;
