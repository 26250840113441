import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './en/translation.json';
import esTranslation from './es/translation.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
};
const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

export const defaultNS = 'translation';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    debug: true,
    resources,
    defaultNS,
    supportedLngs: ['en', 'es'],
    fallbackLng: 'en',
  });
