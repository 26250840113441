import axios from 'axios';
import config from '../config/config';

export const requestVerificationCode = async (username: string) => {
  const { data } = await axios.post(
    `${config.milestonesAPIBaseUrl}/signin/requestverificationcode`,
    { username },
  );
  return data;
};

export const sendMagicLink = async (
  loginIdentity: string,
  loginIdentityType: 'email' | 'phone',
  companySubdomain: string,
  path: string,
) => {
  const { data } = await axios.post(
    `${config.milestonesAPIBaseUrl}/signin/sendmagiclink`,
    { loginIdentity, loginIdentityType, companySubdomain, path },
  );
  return data;
};

export const getLoginIdentityFromAuthToken = async (
  authToken: string | null,
) => {
  if (authToken) {
    const { data } = await axios.get(
      `${config.milestonesAPIBaseUrl}/signin/loginidentity/${authToken}`,
    );
    return data;
  }
  return null;
};
