const config: any = {};

config.env = 'prod';


// HON-2961 add handling of company subdomain and add next
config.clientApp = {
  baseUrl: 'https://hona.app/',
  nextUrl: 'https://hona.app/',
};

config.posthog = {
  apiKey: 'phc_w7INo7SyGvWP6a7zfOCeRgJqjXSssIHiRtTlXLxWgdy',
  apiHost: 'https://d144cslsyita8a.cloudfront.net',
};

export default config;
