import { merge } from '@global/utils';
import config from './base/config';
import prodConfig from './prod/config';
import devConfig from './dev/config';
import stageConfig from './stage/config';
import reviewConfig from './review/config';

/**
 * Setup configuration
 */
switch (process.env.VITE_ENV) {
  case 'prod': {
    merge(config, prodConfig);
    // console.log('Production environment detected');
    break;
  }
  case 'stage': {
    merge(config, stageConfig);
    console.log('Staging environment detected');
    break;
  }
  case 'review': {
    merge(config, reviewConfig);
    console.log('Review environment detected');
    break;
  }
  default: {
    merge(config, devConfig);
    console.log('Dev environment detected');
    break;
  }
}

export default config;
