/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { Suspense, lazy, useContext, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';
import { CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import { Amplify, Auth } from 'aws-amplify';
import SuperJSON from 'superjson';
import AuthProvider from './components/Auth/AuthProvider';
import AuthContext from './components/Auth/AuthContext';
import AuthProviderML from './components/AuthML/AuthProviderML';
import AuthContextML from './components/AuthML/AuthContextML';
import config from './config/config';
import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.css';
import { standardTheme } from './config/base/config';
import trpc from './util/trpc';
import { getAuthHeader } from './api/apiClient';
import { hasCookieValue, setCookie } from './util/cookie';
import usePreventInstallPrompt from './hooks/usePreventInstallPrompt';

const Home = lazy(() => import('./components/Home/Home'));
const SignInPageML = lazy(() => import('./components/SignInML/SignInPageML'));

const theme = createTheme(standardTheme);

function AuthRoute() {
  const [URLSearchParams] = useSearchParams();
  const authToken = URLSearchParams.get('c');
  const { isLoggedIn } = useContext(AuthContext);
  const {
    isLoggedIn: isLoggedInML,
    loadingCustomAuthChallenge,
    errorMessage,
  } = useContext(AuthContextML);
  const isPreviewMode =
    typeof window !== 'undefined'
      ? new window.URLSearchParams(window.location.search).get('preview') ===
        'true'
      : false;

  const showLoadingBar =
    (isLoggedIn === null && isLoggedInML === null) ||
    (authToken && !isLoggedInML && loadingCustomAuthChallenge);

  const showHomePage =
    isLoggedInML ||
    isLoggedIn ||
    (config.env === 'dev' && config.ignoreAuthenticationInDev) ||
    isPreviewMode;

  if (showLoadingBar) {
    return (
      <div className={styles.loading}>
        <div>
          <CircularProgress color="secondary" />
        </div>
        <div>Verifying account...</div>
      </div>
    );
  }

  if (showHomePage) {
    return <Home />;
  }

  return <SignInPageML startingErrorMessage={errorMessage || ''} />;
}

function AuthSession() {
  const isReviewApp = config.env === 'review';

  const auth: any = {
    region: config.cognito.REGION,
    userPoolId: config.cognito.clientAccountPoolId,
    userPoolWebClientId: config.cognito.appwebclientId,
  };

  if (!isReviewApp) {
    auth.cookieStorage = config.cognito.cookieStorage;
  }

  Amplify.configure({
    Auth: auth,
  });

  setCookie('session-type', 'magic-link');

  return (
    <AuthProviderML>
      <>
        <Suspense
          fallback={
            <div className={styles.loading}>
              <div>
                <CircularProgress color="secondary" />
              </div>
              <div>Verification Complete. Redirecting...</div>
            </div>
          }
        >
          {' '}
          <AuthRoute />
        </Suspense>
        <ToastContainer theme="colored" newestOnTop />
      </>
    </AuthProviderML>
  );
}

const isPreviewMode =
  typeof window !== 'undefined'
    ? new URLSearchParams(window.location.search).get('preview') === 'true'
    : false;

const previewModeString = isPreviewMode ? '?preview=true' : '';

function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${config.milestonesAPIBaseUrl}/trpc`,
          fetch: async (input, init) => {
            const url = new URL(input, window.location.href);
            url.searchParams.set('preview', isPreviewMode ? 'true' : 'false');
            return fetch(url, init);
          },
          headers: async () => {
            const headers: Record<string, string> = {};

            if (config.env === 'dev' && config.ignoreAuthenticationInDev) {
              return headers;
            }

            if (isPreviewMode) {
              return headers;
            }

            const session = await Auth.currentSession();
            headers.Authorization = getAuthHeader(session);

            return headers;
          },
        }),
      ],
      transformer: SuperJSON,
    }),
  );
  usePreventInstallPrompt();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/*" element={<AuthSession />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
