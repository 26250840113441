import { useEffect } from 'react';

/**
 * Custom React hook to prevent the display of the "install app" banner 
 * that is triggered by the `beforeinstallprompt` event.
 *
 * This hook listens for the `beforeinstallprompt` event and calls 
 * `event.preventDefault()` to stop the browser from showing the default 
 * install prompt or banner. This can be useful if you want to control 
 * when and how users are prompted to install your app, or if you want 
 * to prevent the prompt entirely.
 *
 * Usage:
 * - Simply call `usePreventInstallPrompt()` inside any component to 
 *   prevent the install prompt from being displayed.
 * 
 * Note:
 * - This hook uses a type assertion (`as EventListener`) to work around 
 *   TypeScript's type system limitations, as the `beforeinstallprompt` 
 *   event is not part of the standard TypeScript library.
 */

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>;
    prompt(): Promise<void>;
}

const usePreventInstallPrompt = () => {
    useEffect(() => {
        const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
            event.preventDefault();
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
        };
    }, []);
}

export default usePreventInstallPrompt;
